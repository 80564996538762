.chartModal {
  background-color: #23242727;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 100svh;
  z-index: 1000;
}

.chartModalWrapper {
  margin: 0 auto;
  width: fit-content;
  max-width: 100%;
  padding: 16px;
}

.chartModalBtn {
  position: absolute;
  top: 24px;
  right: 24px;
}

.chartModalBtn svg:last-child {
  cursor: pointer;
}

.chartModalBtn svg:last-child:hover {
  fill: var(--blue);
}

.chartModalChart {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  min-height: calc(360px + 16px + 1.44rem);
  padding: 24px;
}

.chartModalTitle {
  color: var(--black);
  font-size: clamp(1.24rem, 2svw, 1.44rem);
  font-family: var(--font-family);
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: -0.25px;
  margin-bottom: 8px;
}

.chartModalTitle:first-child {
  padding-right: 52px;
}

.chartModal ul {
  margin-top: 16px;
}

.chartModal ul li {
  color: var(--medium);
  font-size: 0.8rem;
  margin-left: 16px;
  line-height: 1.4;
  margin-bottom: 4px;
}