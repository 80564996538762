.ensLink {
  display: flex;
  align-items: center;
  gap: 2px;
  flex-wrap: nowrap;
}

.ensLink:hover svg {
  fill: var(--blue);
}

.ensLink span {
  color: var(--dark-blue);
  font-size: 1rem;
  max-width: 180px;
  white-space: nowrap;
  word-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}

.ensLink:hover span {
  color: var(--blue);
}