.pairwiseChart {
  max-width: initial;
  position: relative;
}

.pairwiseChart svg {
  max-width: 100%;
}

.pairwiseChartTooltip {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  display: none;
  font-size: 12px;
  width: auto;
  padding: 8px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.pairwiseChartTooltip div {
  display: grid;
  grid-template-columns: 68px 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 4px;
  min-width: 280px;
  max-width: 360px;
}

.pairwiseChartTooltip span {
  color: var(--medium);
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;
  word-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pairwiseChartTooltip strong {
  color: var(--black);
  font-weight: 600;
}