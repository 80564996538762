.pageTabsContainer {
  max-width: var(--max-width);
  margin-inline: auto;
  margin-bottom: 24px;
}

.pageTabs {
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}

.pageTab,
.pageTabBtn {
  border-radius: 4px;
  cursor: pointer;
  padding: 8px;
  text-wrap: nowrap;
}

.pageTab span,
.pageTabBtn span {
  color: var(--medium);
  font-size: 14px;
}

.pageTabBtn {
  cursor: not-allowed;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.pageTabBtn span:first-child {
  color: #60606080;
}

.pageTabBtn span:last-child {
  display: none;
  background-color: #F1E9F6;
  color: #6B16A6;
  border-radius: 2px;
  font-size: 12px;
  line-height: 12px;
  padding: 4px;
}

.pageTab.active {
  background-color: var(--light-blue);
}

.pageTab.active span,
.pageTab:hover span {
  color: var(--blue);
}

.pageTabsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  padding: 16px 0;
}

@media screen and (min-width: 468px) {

  .pageTab,
  .pageTabBtn {
    padding: 8px 12px;
  }

  .pageTab span,
  .pageTabBtn span {
    font-size: 16px;
  }

  .pageTabBtn span:last-child {
    display: inline-block;
  }
}